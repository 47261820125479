<template>
  <overlay-page :visible="is_loading">
    <div class="p-5">
      <b-card class="box-card-shadow p-3">
        <div class="d-flex">
          <div class="text-primary">
            {{$t('affiliate.manage_partner.notification.noti_channel')}}
          </div>
        </div>
        <div class="mt-3">
          <div>
            <b-form-checkbox-group
                v-model="notification_data"
                :options="options"
                switches
                stacked
            />
          </div>
        </div>
      </b-card>
      <div class="text-right mt-5">
        <b-row align-h="between">
          <b-col class="text-left">
          </b-col>
          <b-col class="text-right">
            <b-button variant="primary" class="w-25" @click="updateNotifications">
              {{ $t('button.save') }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </div>
  </overlay-page>
</template>
<script>
import affiliateApi from "@/repository/affiliateApi";
import OverlayPage from "@/components/common/overlayPage.vue";
import alert from "@/common/alert";

export default {
  name: "notification",
  components: {OverlayPage},
  data () {
    return {
      notification_data: [],
      options: [
        { text: 'SMS', value: 'sms' },
        { text: 'E-mail', value: 'email' },
      ],
      is_loading: false
    }
  },
  mounted() {
    this.getMemberInfo()
  },
  methods: {
    getMemberInfo() {
      this.is_loading = true
      affiliateApi.getMemberDetailAffiliate().then(resp => {
        if (!!resp) {
         this.notification_data = resp?.notifications
        }
      }).catch(err => {
        throw err
      }).finally(() => {
        this.is_loading = false
      })
    },
    updateNotifications () {
      const payload = {
        notifications : this.notification_data
      }
      this.$swal.fire({
        icon: 'warning',
        title: this.$t('affiliate.manage_partner.confirm_save.topic'),
        text: this.$t('affiliate.manage_partner.confirm_save.detail'),
        showCancelButton: true,
        confirmButtonColor: '#19CBAC',
        cancelButtonColor: '#5B656E',
        cancelButtonText: this.$t('button.cancel'),
        confirmButtonText: this.$t('button.confirm'),
      }).then((result) => {
        if (result.isConfirmed) {
          affiliateApi.updateNotification(payload).then(resp => {
            if (!!resp) {
              alert.updateSuccess(this)
              this.getMemberInfo()
            }
          }).catch(err => {
            alert.updateFailed(this, err.response?.data?.message)
          })
        }
      })
    }
  }
}
</script>


<style scoped>

</style>