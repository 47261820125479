<template>
  <div v-if="checkPermission('setting', 'action', 'affiliate')">
    <topic-component :topic="$t('affiliate.topic')"/>
    <div>
      <b-row>
        <b-col  sm="12" md="12" lg="3" class="mb-2">
          <b-card class="mb-1 border send-box-bg" border-variant="primary" v-for="(menu, index) in menu" :key="index"  @click="activeMenu(menu.key)" :class="menuSelected === menu.key ?'active' :''">
            <b-row>
              <b-col cols="4">
                <div>
                  <i :class="`${menu.imgAction} display-4 `"></i>
                </div>
              </b-col>
              <b-col cols="8">
                <h4 class="card-title">{{ $t(menu.text) }}</h4>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col sm="12" md="12" lg="9">
          <b-card v-if="menuSelected!=='' ">
            <bank-account v-if="menuSelected === 'bank_account'"/>
            <notification v-if="menuSelected === 'notification'"/>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import TopicComponent from "@/components/common/topicComponent.vue";
import BankAccount from "@/views/affiliate/managementPartner/bankAccount.vue";
import Notification from "@/views/affiliate/managementPartner/notification.vue";
import functions from "@/common/functions";

export default {
  name: "index",
  components: {Notification, BankAccount, TopicComponent},
  data () {
    return {
      menuSelected: 'bank_account',
      menu: [
        {
          key: 'bank_account',
          text: 'affiliate.account_info',
          imgAction: 'fa fa-credit-card',
          permissions: ''
        },
        {
          key: 'notification',
          text: 'affiliate.notification',
          imgAction: 'fas fa-bell',
          permissions: ''
        }
      ]
    }
  },
  mounted() {
    let active = sessionStorage.getItem('_a_menu_manage_account')
    if (active) {
      this.menuSelected = active || 'bank_account'
    } else {

    }
  },
  methods: {
    activeMenu(menu) {
      sessionStorage.setItem('_a_menu_manage_account', menu)
      this.menuSelected = menu
    },
    checkPermission(permission, type, modules) {
      return functions.userCheckPermission(permission, type, modules)
    },
  }
}
</script>


<style scoped>

</style>