<template>
  <overlay-page :visible="is_loading">
    <div class="pl-5 pr-5 pt-4 pb-4">
      <div class="mb-5">
        {{ $t('affiliate.manage_partner.enter_account') }}
      </div>
      <div>
        <b-row>
          <b-col sm="12" md=12 lg="6" xl="6">
            <input-component :label-input="$t('affiliate.manage_partner.name_account')" required
                             v-model="$v.bank_info.account_name.$model"
                             :state="validateState('account_name')"
                             :require-label="validateState('account_name') !== null && !$v.bank_info.account_name.required ? $t('common.requiredField'): ''" :disabled-type="!is_edit"/>
          </b-col>
          <b-col sm="12" md=12 lg="6" xl="6">
            <input-component :label-input="$t('affiliate.manage_partner.account_no')" required
                             :disabled-type="!is_edit"
                             @keypress="checkIsNumber"
                             v-model="$v.bank_info.account_no.$model"
                             :state="validateState('account_no')"
                             @keyup="checkFormatFuncOnlyNumber"
                             :require-label="validateState('account_no') !== null && !$v.bank_info.account_no.required ? $t('common.requiredField'): ''" />
          </b-col>
          <b-col sm="12" md=12 lg="6" xl="6">
            <select-component :label-select="$t('affiliate.register.bank_detail.account')" required v-model="$v.bank_info.bank.$model"
                              :state="validateState('bank')"
                              :options="bank_list"
                              text-field="text"
                              :disabled-type="!is_edit"
                              :require-label="validateState('bank') !== null && !$v.bank_info.bank.required ? $t('common.requiredField'): ''"/>
          </b-col>
        </b-row>
        <div class="mt-3">
          <upload-file-signature-component :title="$t('affiliate.manage_partner.img_account')"
                                           :text-upload-content="$t('affiliate.manage_partner.upload_file')"
                                           :value="bank_info.book_bank"
                                           :close-icon="is_edit"
                                           required
                                           @input="handleFileUploadBookBank"/>
        </div>
        <div class="mt-5" v-if="is_edit">
          <b-row align-h="between">
            <b-col class="text-left">
              <b-button variant="outline-primary" class="w-25" @click="isCancelEditData">
                {{ $t('affiliate.manage_partner.cancel') }}
              </b-button>
            </b-col>
            <b-col class="text-right">
              <b-button variant="primary" :disabled="!bank_info.book_bank || !bank_info.book_bank || !bank_info.account_no" class="w-25" @click="updateMemberInfo">
                {{ $t('button.save') }}
              </b-button>
            </b-col>
          </b-row>
        </div>
        <div v-else class="mt-5">
          <b-row align-h="between">
            <b-col class="text-left">
            </b-col>
            <b-col class="text-right">
              <b-button variant="outline-primary" class="w-25" @click="isEditData">
                {{ $t('affiliate.manage_partner.edit') }}
              </b-button>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </overlay-page>

</template>
<script>
import InputComponent from "@/components/common/inputComponent.vue";
import UploadFileSignatureComponent from "@/components/common/uploadFileSignatureComponent.vue";
import affiliateApi from "@/repository/affiliateApi";
import alert from "@/common/alert";
import functionsCommon from "@/common/functions";
import {required} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";
import OverlayPage from "@/components/common/overlayPage.vue";
import SelectComponent from "@/components/common/selectComponent.vue";

export default {
  name: "bankAccount",
  components: {SelectComponent, OverlayPage, UploadFileSignatureComponent, InputComponent},
  mixins: [validationMixin],
  data() {
    return {
      bank_info: {
        account_name: "",
        account_no: "",
        bank: null,
        book_bank: ""
      },
      is_edit: false,
      is_loading:false,
      bank_list: []
    }
  },
  validations: {
    bank_info: {
      account_name: {
        required,
      },
      account_no: {
        required,
      },
      bank: {
        required,
      }
    },
  },
  mounted() {
    this.getMemberInfo()
    this.getBankAllList()
  },
  methods: {
    validateState(username) {
      const {$dirty, $error} = this.$v.bank_info[username]
      return $dirty ? !$error : null
    },
    getMemberInfo() {
      this.is_loading = true
      affiliateApi.getMemberDetailAffiliate().then(resp => {
        if (!!resp) {
          this.bank_info = {
            ...this.bank_info,
            account_name: resp?.bank_info?.account_name,
            account_no: resp?.bank_info?.account_no,
            bank: resp?.bank_info?.bank,
            book_bank: resp?.bank_info?.book_bank,
          }
        }
      }).catch(err => {
        throw err
      }).finally(() => {
        this.is_loading = false
      })
    },
    checkFormatFuncOnlyNumber() {
      const regex = /[^0-9]/g
      const isNumber = this.bank_info.account_no.replace(regex, '')
      this.bank_info.account_no = isNumber
    },
    handleFileUploadBookBank(e) {
      this.bank_info.book_bank = e
    },
    updateMemberInfo() {
      const payload = {
        account_name: this.bank_info.account_name,
        account_no: this.bank_info.account_no,
        bank: this.bank_info.bank,
        book_bank: this.bank_info.book_bank,
      }
      this.$swal.fire({
        icon: 'warning',
        title: this.$t('affiliate.manage_partner.confirm_save.topic'),
        text: this.$t('affiliate.manage_partner.confirm_save.detail'),
        showCancelButton: true,
        confirmButtonColor: '#19CBAC',
        cancelButtonColor: '#5B656E',
        cancelButtonText: this.$t('button.cancel'),
        confirmButtonText: this.$t('button.confirm'),
      }).then((result) => {
        if (result.isConfirmed) {
          affiliateApi.updateMemberInfoDetail(payload).then(resp => {
            if (!!resp) {
              alert.updateSuccess(this)
              this.getMemberInfo()
              this.is_edit = false
            }
          }).catch(err => {
            alert.updateFailed(this, err.response?.data?.message)
          })
        }
      })
    },
    isEditData() {
      this.is_edit = true
    },
    isCancelEditData() {
      this.is_edit = false
      this.getMemberInfo()
    },
    checkIsNumber (e) {
      return functionsCommon.checkNumber(e)
    },
    getBankAllList () {
      affiliateApi.getMasterBankAccountList().then(resp => {
        this.bank_list = _.map(resp.data, item => {
          return {
            text: item.name,
            value: item.value
          }
        })

      }).catch(err => {
        throw err
      })
    },
  }
}
</script>


<style scoped>

</style>